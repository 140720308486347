import { ComponentProps, FC } from 'react';

import { Checkbox } from 'components/Checkbox';
import { Link } from 'components/Link';

export const AgreementCheckbox: FC<ComponentProps<typeof Checkbox>> = (props) => {
	return (
		<Checkbox {...props}>
            Соглашаюсь с <Link url='/agreement'>условиями</Link> и <Link url='/policy'>политикой конфиденциальности</Link>.
		</Checkbox>
	);
};
