import { memo,ReactElement } from 'react';
import block from 'utils/bem-css-module';

import style from './or.scss';


const b = block(style);

export const Or = memo((): ReactElement => (
	<div className={b()}>или</div>
));
