import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { BeforeLoginPopup } from 'components/Login';


const root = createRoot(document.querySelector('#before-login-popup')!);
root.render(
	<BrowserRouter>
		<BeforeLoginPopup isOpen={true} />
	</BrowserRouter>,
);
