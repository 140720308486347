import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import block from 'utils/bem-css-module';
import { asyncRetry, post } from 'utils/fetch';

import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Link } from 'components/Link';
import { SocialBlock } from 'components/SocialBlock';
import { apiPath } from 'constants/api-path';
import { YANDEX_METRIC_ID } from 'constants/common';

import { Or } from './or';

import style from './before-login-popup.scss';

const b = block(style);


export const BeforeLoginContent = () => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const onGetFakeUser = async (): Promise<void> => {
		setLoading(true);
		ym(YANDEX_METRIC_ID,'reachGoal','click-without-registration');
		await asyncRetry(post, 3)(apiPath.USER.CREATE_FAKE_USER);
		window.location.reload();
		setLoading(false);
	};

	const onRegistration = () => {
		history.push('/regist/');
	};

	const onLogin = () => {
		history.push('/login/');
	};

	return (
		<>
			<img
				src='/static/fonts/logo.svg'
				alt='PrintBox'
				width='270'
				height='59'
				className={b('logo')}
			/>
			<Heading
				size='l'
				level={1}
				className={b('heading', { center: true })}
				id='before-login-modal-header'
			>
                Печать документов<br/>
                рядом с домом
			</Heading>
			<Heading level={2} size='sm' className={b('description')} id='before-login-modal-body'>
                Пользуйся <b>ПринтБокс</b> с любого устройства и печатай свои документы там, где удобно!
			</Heading>
			<Button
				buttonType='success'
				size='m'
				className={b('button')}
				onClick={onGetFakeUser}
				loading={loading}
			>
				<p className={b('button-p-desktop')}>Продолжить без регистрации</p>
				<p className={b('button-p-mobile')}>Без регистрации</p>
			</Button>
			<div className={b('buttons')}>
				<Button
					buttonType='default'
					size='m'
					className={b('button', { type: 'login' })}
					onClick={onLogin}
				>
					Вход
				</Button>
				<Button
					buttonType='default'
					size='m'
					className={b('button')}
					onClick={onRegistration}
				>
                    Регистрация
				</Button>
			</div>
			<Link url='/printers' size='sm' className={b('map-link')} target='_self'>
				Найти ближайший пункт печати
			</Link>
			<div className={b('social', { hidden: true })}>
				<Or />
				<SocialBlock />
			</div>
		</>
	);
};
