import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import block from 'utils/bem-css-module';

import { Modal, ModalContent } from 'components/Modal';

import { BeforeLoginContent } from './before-login-content';
import { Login } from './login';
import { Recovery } from './recovery';
import { Registration } from './registration';

import style from './before-login-popup.scss';

const b = block(style);

type Props = {
	isOpen: boolean;
}

export const BeforeLoginPopup: FC<Props> = ({
	isOpen,
}) => {
	const location = useLocation();
	
	const content = useMemo(() => {
		switch (location.pathname) {
			case '/login/': return <Login />;
			case '/regist/': return <Registration />;
			case '/recovery': return <Recovery />;
			default: return <BeforeLoginContent />;
		}
	}, [location.pathname]);

	return (
		<Modal
			id='before-login-modal'
			isOpen={isOpen}
			className={b()}
		>
			<ModalContent className={b('content')} size='sm' id='before-login-modal'>
				{content}
			</ModalContent>
		</Modal>
	);
};
