import { AnchorHTMLAttributes, FC, MouseEvent, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import block from 'utils/bem-css-module';

import style from './link.scss';


type Props = {
    children: ReactNode;
    url: string;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    className?: string;
    size?: 'xs' | 's' | 'sm' | 'm' | 'l' | 'xl';
}

const b = block(style);

export const InternalLink: FC<Props & AnchorHTMLAttributes<HTMLElement>> =({
	children,
	url,
	onClick,
	size,
	className = '',
	...rest
}) => {
	return (
		<NavLink
			to={url}
			className={b({ size }).mix(className)}
			onClick={onClick}
			{...rest}
		>
			{children}
		</NavLink>
	);
};
