import { FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import style from './social-block.scss';

const b = block(style);

export const SocialBlock: FC<HTMLAttributes<HTMLUListElement>> = ({
	className,
	...rest
}) => {
	return (
		<ul className={b().mix(className)} {...rest}>
			<li key='vk'>
				<a
					className={b('icon-link', { type: 'vk' })}
					href='/login/vk-oauth2/'
					aria-label='vk'
				/>
			</li>
			<li key='google'>
				<a
					className={b('icon-link', { type: 'google-plus' })}
					href='/login/google-oauth2/'
					aria-label='google'
				/>
			</li>
			<li key='yandex'>
				<a
					className={b('icon-link', { type: 'yandex' })}
					href='/login/yandex-oauth2/'
					aria-label='yandex'
				/>
			</li>
			<li key='mail'>
				<a
					className={b('icon-link', { type: 'mailru' })}
					href='/login/mailru-oauth2/'
					aria-label='mail'
				/>
			</li>
		</ul>
	);
};
